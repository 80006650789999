<style>.footer-text {
  font-size: 18px;
  line-height: 200%;
}

svg:hover .svg-icon {
  stroke: #465D73;
}</style>

<footer class="py-4 mt-8 bg-gray-100 md:mt-16">
  <div
    class="relative w-11/12 mx-auto text-center text-white bg-brand-gray py-11"
    style="top:-40px"
  >
    <div class="w-3/4 max-w-screen-lg mx-auto space-y-4">
      <h3 class="display-one">
        Boost your performance, build a better business
      </h3>
      <a href="https://www.proximity.tech/contact" class="inline-block">
        <button
          class="px-8 py-3 font-extrabold md:px-10 bg-brand-red text-label-extralarge"
        >Let's talk</button>
      </a>
    </div>
  </div>
  <div
    class="flex flex-col justify-between items-center w-11/12 mx-auto -mt-4 font-extrabold tracking-tight md:flex-row footer-text text-brand-gray"
  >
    <span>Proximity Labs Pvt. Ltd.</span>
    <div class="flex mt-4 space-x-3 md:mt-0">
      <a href="https://www.facebook.com/proxlabs" aria-label="Facebook Page">
        <svg class="w-auto h-8 mr-2 md:mr-4" width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path class="svg-icon" d="M46.9242 1H25.5908M46.9242 1L64.6667 46.7964L33.6172 65M46.9242 1L40.2707 33M25.5908 1L0.666748 20.9281V54.6527L33.6172 65M25.5908 1L40.2707 33M33.6172 65L40.2707 33" stroke="#DCE1E6"/>
          <path d="M45.3179 18.7776H20.0142C19.1476 18.7776 18.4438 19.4813 18.4438 20.348V45.6517C18.4438 46.5183 19.1476 47.222 20.0142 47.222H32.6661V35.9628H29.2661V31.8146H32.6661V28.748C32.6661 25.0739 35.2142 23.0739 38.4957 23.0739C40.0661 23.0739 41.755 23.1924 42.1476 23.2443V27.0813H39.5327C37.7476 27.0813 37.4068 27.9257 37.4068 29.1702V31.8146H41.6587L41.1031 35.9628H37.4068V47.222H45.3179C46.1846 47.222 46.8883 46.5183 46.8883 45.6517V20.348C46.8883 19.4813 46.1846 18.7776 45.3179 18.7776Z" fill="#19222F"/>
        </svg>
      </a>
      <a href="https://www.instagram.com/proxlabs/" aria-label="Instagram Page">
        <svg class="w-auto h-8 mr-2 md:mr-4" width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path class="svg-icon" d="M22.2515 65L1.33325 24.5676M22.2515 65L27.2321 1M22.2515 65H65.3332M22.2515 65L1.33325 49.4324V24.5676M1.33325 24.5676L27.2321 1M27.2321 1H40.9286M65.3332 65L40.9286 1M65.3332 65V24.5676L40.9286 1" stroke="#DCE1E6"/>
          <path d="M39.2585 21.148C40.8289 21.148 42.3178 21.7702 43.4437 22.8887C44.5696 24.0072 45.1844 25.5035 45.1844 27.0739V38.9257C45.1844 40.4961 44.5622 41.985 43.4437 43.1109C42.3252 44.2368 40.8289 44.8517 39.2585 44.8517H27.4066C25.8363 44.8517 24.3474 44.2294 23.2215 43.1109C22.0955 41.9924 21.4807 40.4961 21.4807 38.9257V27.0739C21.4807 25.5035 22.1029 24.0146 23.2215 22.8887C24.34 21.7628 25.8363 21.148 27.4066 21.148H39.2585ZM39.2585 18.7776H27.4066C22.8437 18.7776 19.1104 22.5109 19.1104 27.0739V38.9257C19.1104 43.4887 22.8437 47.222 27.4066 47.222H39.2585C43.8215 47.222 47.5548 43.4887 47.5548 38.9257V27.0739C47.5548 22.5109 43.8215 18.7776 39.2585 18.7776Z" fill="#19222F"/>
          <path d="M41.036 27.0739C40.0508 27.0739 39.2582 26.2813 39.2582 25.2961C39.2582 24.3109 40.0508 23.5183 41.036 23.5183C42.0138 23.5183 42.8138 24.3109 42.8138 25.2961C42.8138 26.2813 42.0138 27.0739 41.036 27.0739ZM33.3323 28.2591C35.9471 28.2591 38.073 30.385 38.073 32.9998C38.073 35.6146 35.9471 37.7405 33.3323 37.7405C30.7175 37.7405 28.5916 35.6146 28.5916 32.9998C28.5916 30.385 30.7175 28.2591 33.3323 28.2591ZM33.3323 25.8887C29.4064 25.8887 26.2212 29.0739 26.2212 32.9998C26.2212 36.9257 29.4064 40.1109 33.3323 40.1109C37.2582 40.1109 40.4434 36.9257 40.4434 32.9998C40.4434 29.0739 37.2582 25.8887 33.3323 25.8887Z" fill="#19222F"/>
        </svg>
      </a>
      <a href="https://twitter.com/proxlabs" aria-label="Twitter Page">
        <svg class="w-auto h-8 mr-2 md:mr-4" width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path class="svg-icon" d="M65 49.1987L46.7157 64.9999L24.8783 58.6686L1.00001 30.2385L65 49.2324" stroke="#DCE1E6" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
          <path class="svg-icon" d="M24.8783 0.999998L65 49.4061L51.1108 17.4885M24.8783 0.999998L41.8513 6.36121L51.1108 17.4885M24.8783 0.999998L1 29.5216L51.1108 17.4885" stroke="#DCE1E6" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M47.7437 23.8435C46.6562 24.3248 45.4937 24.6498 44.2687 24.7998C45.5187 24.0498 46.4812 22.8623 46.9312 21.4498C45.7625 22.1435 44.4687 22.6498 43.0875 22.9185C41.9812 21.7373 40.4062 20.9998 38.6687 20.9998C35.325 20.9998 32.6187 23.7123 32.6187 27.056C32.6187 27.531 32.6687 27.9935 32.775 28.4373C27.7437 28.1873 23.2812 25.7748 20.3 22.106C19.7812 22.9998 19.4812 24.0435 19.4812 25.1498C19.4812 27.2498 20.5562 29.106 22.1812 30.1935C21.1812 30.1685 20.2437 29.8935 19.4312 29.4373V29.5123C19.4312 32.4498 21.5187 34.8935 24.2875 35.4498C23.7812 35.5873 23.2437 35.6623 22.6937 35.6623C22.3062 35.6623 21.925 35.6248 21.5562 35.5498C22.325 37.956 24.5625 39.706 27.2125 39.756C25.1437 41.381 22.5312 42.3498 19.6937 42.3498C19.2062 42.3498 18.725 42.3185 18.25 42.2623C20.9187 43.9998 24.1 44.9998 27.5125 44.9998C38.6562 44.9998 44.7437 35.7685 44.7437 27.7623C44.7437 27.4998 44.7375 27.2373 44.725 26.981C45.9062 26.1248 46.9312 25.0623 47.7437 23.8435Z" fill="#19222F"/>
        </svg>
      </a>
      <a
        href="https://www.linkedin.com/company/proxlabs/"
        aria-label="Linkedin Page"
      >
        <svg class="w-auto h-8 mr-2 md:mr-4" width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path class="svg-icon" d="M16.3754 1L48.4715 6.92593L65 32.3228M16.3754 1L42.1291 65M16.3754 1L1 43.328L21.1802 62.4603M16.3754 1L21.1802 62.4603M65 32.3228L60.3874 62.4603L42.1291 65M65 32.3228L21.1802 62.4603M42.1291 65L21.1802 62.4603" stroke="#DCE1E6"/>
          <path d="M44.9413 18.7776H21.208C19.9117 18.7776 18.7783 19.7109 18.7783 20.9924V44.7776C18.7783 46.0665 19.9117 47.2146 21.208 47.2146H44.9339C46.2376 47.2146 47.2154 46.0591 47.2154 44.7776V20.9924C47.2228 19.7109 46.2376 18.7776 44.9413 18.7776ZM27.5931 42.4813H23.5191V29.8146H27.5931V42.4813ZM25.6968 27.8887H25.6672C24.3635 27.8887 23.5191 26.9183 23.5191 25.7035C23.5191 24.4665 24.3857 23.5183 25.7191 23.5183C27.0524 23.5183 27.8672 24.4591 27.8968 25.7035C27.8968 26.9183 27.0524 27.8887 25.6968 27.8887ZM42.482 42.4813H38.408V35.5554C38.408 33.8961 37.8154 32.7628 36.3413 32.7628C35.2154 32.7628 34.5487 33.5257 34.2524 34.2665C34.1413 34.5331 34.1117 34.8961 34.1117 35.2665V42.4813H30.0376V29.8146H34.1117V31.5776C34.7042 30.7331 35.6302 29.5183 37.7857 29.5183C40.4598 29.5183 42.482 31.2813 42.482 35.0813V42.4813Z" fill="#19222F"/>
        </svg>
      </a>
    </div>
  </div>
</footer>
