<script>
  import fadeScale from "../utils/fadeScaleTransition";
  import { cubicIn, cubicOut } from "svelte/easing";
  import { navigationLinks } from "../utils/navigationData.js";
  let open = false;
  export let hideMobileHeader;
</script>

<style>.nav-link {
  border-bottom-color: transparent;
  border-bottom-style: solid;
  border-bottom-width: 2px;
}

.nav-link:hover {
  border-bottom-style: solid;
  border-bottom-color: #fa4545;
  transform: translate(0, -2px);
}</style>

<nav class="flex items-center justify-between ">
  <div class="flex items-center flex-1">
    <div class="flex items-center justify-between w-full md:w-auto">
      <div class="flex items-end md:items-center">
        <a href="/" aria-label="Home">
          <img
            loading="eager"
            class="w-auto h-6 mr-2 md:mr-4"
            src="/assets/images/logo-unit.svg"
            alt="Logo"
          />
        </a>
      </div>
      {#if !hideMobileHeader}
        <div class="flex items-end -mr-2 md:hidden">
          <button
            type="button"
            on:click="{() => (open = true)}"
            class="inline-flex items-center justify-center p-2 text-white transition duration-300 ease-in-out rounded-md focus:outline-none"
            id="main-menu"
            aria-label="Main menu"
            aria-haspopup="true"
          >
            <svg
              class="w-5 h-5"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 6h16M4 12h16M4 18h16"
              ></path>
            </svg>
          </button>
        </div>
      {/if}
    </div>
  </div>
  <div class="hidden md:flex md:ml-10">
    {#each navigationLinks as { name, url }}
      <a
        href="{url}"
        class="font-medium text-white font-bold transition duration-150 ease-in-out hover:text-gray-300 ml-5 nav-link"
      >{name}</a>
    {/each}
  </div>
</nav>
{#if open}
  <div
    in:fadeScale="{{ duration: 150, baseScale: 0.95, easing: cubicOut }}"
    out:fadeScale="{{ duration: 150, baseScale: 0.95, easing: cubicIn }}"
    class="fixed inset-x-0 top-0 p-2 transition origin-top-right transform md:hidden"
  >
    <div class="rounded-lg shadow-md">
      <div
        class="overflow-hidden bg-white rounded-lg shadow-xs"
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="main-menu"
      >
        <div class="flex items-center justify-end px-0 pt-0">
          <div class="hidden">
            <a href="/" aria-label="Home" class="flex items-center space-x-4">
              <img
               loading="eager"
                class="w-auto h-5"
                src="/assets/images/Proximity-Logo-GreyBlue.svg"
                alt=""
              />
              <p class="text-2xl uppercase text-brand-red">
                <span class="text-brand-gray">| </span>Peak
              </p>
            </a>
          </div>
          <div>
            <button
              on:click="{() => (open = false)}"
              type="button"
              class="inline-flex items-center justify-center p-2 transition duration-150 ease-in-out rounded-md text-brand-gray hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-100"
              aria-label="Close menu"
            >
              <svg
                class="w-5 h-5"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </button>
          </div>
        </div>

        <div class="-mt-6">
          <div class="px-2 pt-2 pb-3 space-y-1">
            {#each navigationLinks as { url, name }}
              <a
                href="{url}"
                class="block px-3 py-2 text-base font-medium text-gray-700 transition duration-150 ease-in-out rounded-md hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50"
                role="menuitem"
              >{name}</a>
            {/each}
          </div>
        </div>
      </div>
    </div>
  </div>
{/if}
