<style>.tooltip {
  position: absolute;
  opacity: 0;
  margin-left: 0px;
  transition: opacity 0.3s, transform 0.3s;
  pointer-events: none;
}

.show {
  opacity: 1;
  transform: translate3d(0.5rem, 0, 0);
  transition: opacity 0.3s, transform 0.3s;
}

.show.top,
  .show.top-right {
  transform: translate3d(0, -0.5rem, 0);
}

.show.bottom {
  transform: translate3d(0, 0.5rem, 0);
}

.tooltip::after {
  content: "";
  border: solid transparent;
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: transparent;
  border-width: 6px;
}

.right.tooltip::after {
  top: 50%;
  left: 0%;
  border-right-color: #465d73;
  margin-top: -6px;
  margin-left: -12px;
}

.left.tooltip::after {
  top: 50%;
  left: 100%;
  border-left-color: #465d73;
  border-width: 6px;
  margin-top: -6px;
}

.top.tooltip::after {
  top: 100%;
  left: 50%;
  border-top-color: #465d73;
  border-width: 6px;
  margin-top: 0px;
}

.bottom.tooltip::after {
  top: 0%;
  left: 50%;
  border-bottom-color: #465d73;
  border-width: 6px;
  margin-top: -12px;
}

.top-right.tooltip::after {
  top: 100%;
  left: 90%;
  border-top-color: #465d73;
  border-width: 6px;
  margin-top: 0px;
}

.bottom-right.tooltip::after {
  top: 0%;
  left: 90%;
  border-bottom-color: #465d73;
  border-width: 6px;
  margin-top: -12px;
}</style>

<script>
  import { fade } from "svelte/transition";
  import { cubicIn, cubicOut } from "svelte/easing";
  import deviceInfo from "../utils/deviceInfo";

  export let icon = "info_outlined";
  export let direction = "right";
  export let message = "";
  export let show = false;
  export let width = "100px";
  export let height = "48px";
  export let text = "text-white";
  export let offset = "0px";
  let { device } = deviceInfo(window.navigator.userAgent);

  $: getTooltipTop = () => {
    switch (direction) {
      case "right":
      case "left":
        return (parseInt(height) / 2 - 7) * -1 + "px";
      case "top":
      case "top-right":
        return (parseInt(height) - 7) * -1 + "px";
      case "bottom":
      case "bottom-right":
        return "1.5rem";
    }
  };

  $: getTooltipLeft = () => {
    switch (direction) {
      case "top":
      case "bottom":
        return (parseInt(width) / 2) * -1 + "px";
      case "top-right":
      case "bottom-right":
        return parseInt(width) * 0.9 * -1 + "px";
      case "right":
        return "1rem";
      case "left":
        return parseInt(height) * -1 + "px";
    }
  };
</script>

<span class="relative inline-block ">
  <i
    class="text-gray-700 material-icons text-ui-label cursor-pointer"
    style="width:16px;"
    title="{device.type === 'mobile' ? message : ''}"
    on:mouseenter="{() => (show = true)}"
    on:mouseleave="{() => (show = false)}"
    on:pointerenter="{() => (show = true)}"
    on:pointerleave="{() => (show = false)}"
  >{icon}</i>
  <span
    class="tooltip p-4 bg-gray-900 {text} normal-case {direction}"
    class:show
    style="width:{width};top:{getTooltipTop()};left:{getTooltipLeft()};"
  >
    {message}
  </span>
</span>
